(function () {
    'use strict';
    $(document).ready(function () {
        //Set an app Cookie for Appmode
        var forApp = window.location.href.match(/forApp=true\&?/) || window.location.href.match(/hideheader=true\&?/) || window.location.href.match(/hideheader\&?/);
        if ( forApp && 1 === forApp.length) {
            $.cookie('forApp', true, {path: '/'});
        }
        //Adding class for Appmode
        if ($.cookie('forApp')) {
            $('body').addClass('app-mode');
        }
    });
}(vf));
