(function() {
    vf['world-manual-article'] = {
        settings: {
            target: '.mod-world-manual-article',
            steps: '.wm-steps',
            step: '.wm-step',
            indicator: '.wm-indicator',
            img: '.wm-img',
            active: 'wm-active',
            infodition: '.infodition',
            carousel: '.wm-carousel',
            display: '.wm-display',
            disabled: '.wm-disabled',
            nav: '.wm-nav [data-direction]',
            page: '.wm-page',
            refresh: '.wm-refresh',
            mouseover: '[onmouseover]',
            data: {
                xmlurl: 'xml-url',
                service: 'service',
                direction: 'direction',
                page: 'page'
            },
            templates: {
                manual: '<div class="wm"><div class="wm-title"><h3>{{title}}</h3><p>{{info}}</p></div><ol>{{steps}}<ol></div>',
                ol: '<li><div class="h4">{{heading}}</div><ol class="wm-steps">{{steps}}</ol></li>',
                carousel: '<div class="wm-carousel" data-page="0"><div class="wm-nav wm-nav-primary" aria-hidden="true"><a href="#" class="wm-disabled" data-direction="-1"><span class="access">Previous page</span><i class="i-carousel-left"></i></a><a href="#" data-direction="1"><span class="access">Next page</span><i class="i-carousel-right"></i></a></div><div class="wm-body"><ol class="wm-reel wm-steps">{{steps}}</ol></div><div class="wm-page"><span>1</span> of {{stepcount}}</div><div class="wm-icons"><a href="#" class="wm-refresh"><i class="i-refresh-cycle"></i>Refresh</a><a href="#" class="wm-list"><i class="i-list"></i>List</a></div></div>',
                step: '<li class="wm-step"><div class="wm-img" style="width:{{width}}px; height:{{height}}px;"><img src="http://d33ojtfyjxhan3.cloudfront.net/ml/{{imgid}}.jpg" class="lazyloaded"><img class="wm-indicator" src="https://ajuda.vodafone.pt/system/web/view/selfservice/templates/AskOnlineCustomer/img/wmarrows/icon{{indicatorid}}.gif" alt="indicator arrow" style="position: absolute; left: {{left}}px; top: {{top}}px;"></div><div class="wm-txt">{{stepnumber}}{{manual}}</div></li>',
                alt: '<p>{{no-image}}</p>'
            }
        },

        init: function(context) {
            var s = this.settings;

            $(s.target, context).addBack(s.target).each(function() {
                vf['world-manual-article'].loadmanual($(this));
            });
        },

        loadmanual: function(target) {
            var s = this.settings;

            // Load the xml files detailed in data attributes
            $.ajax({
                url: target.data(s.data.xmlurl)
            }).done($.proxy(this.parsemanual, target));
        },

        parsemanual: function(response) {
            var s = vf['world-manual-article'].settings,
                templates = s.templates,
                target = this,
                carousel = 'carousel' == target.data(s.data.service), // Chech if carousel
                manual = carousel ? templates.carousel : templates.manual, // Check if manual
                xml = $(response),
                body = $('<div></div>').html($.parseHTML(xml.find('Body').text())), // Store body
                stepnames = body.clone().children().remove().end().html().split('.'), // Store stepnames which are depicted outside any children thefore we can remove
                stepcount = 0,
                ols = [],
                infoditions = [];
            manual = manual.replace('{{title}}', xml.find('Title').text());
            manual = manual.replace('{{info}}', xml.find('Info').text());

            // Removes first item in array (which will be a number)
            stepnames.shift();

            // Loop through each ul within body
            body.find('ul').each(function(major) {
                var ul = $(this),
                    ol = carousel ? '{{steps}}' : templates.ol, // If carousel place all steps within li
                    substeps = [];
                ol = ol.replace('{{heading}}', (major + 1) + '. ' + stepnames[major].slice(0, -1)),
                    infodition = body.find('ul').eq(major + 1).prev(s.infodition)[0];
                infodition = infodition ? infodition.outerHTML : '';

                ul.find('li').each(function(minor) {
                    var instruction = $(this),
                        html = instruction.html(),
                        mouseovers = instruction.find(s.mouseover);

                    for (var i = 0, _i = Math.max(mouseovers.length, 1); i < _i; i++) {
                        var mouseover = mouseovers[i],
                            step = templates.step;
                        step = step.replace('{{manual}}', html + '<br><br>' + infodition);
                        step = step.replace('{{stepnumber}}', (major + 1) + '.' + (minor + i + 1) + '. ');
                        templates.alt = templates.alt.replace('{{no-image}}', vf.config.worldmanual.articles.noImage);

                        if (mouseover) {
                            var onmouseover = $(mouseover).attr('onmouseover'),
                                properties = onmouseover.substr(onmouseover.indexOf('\''), onmouseover.lastIndexOf('\'')).replace(new RegExp('\'', 'g'), '').split(','),
                                indicatorPosition = vf['world-manual-article'].indicatorPosition(properties[2], properties[3], properties[4], properties[5]);
                            step = step.replace('{{left}}', indicatorPosition.left);
                            step = step.replace('{{top}}', indicatorPosition.top);
                            step = step.replace('{{width}}', properties[4]);
                            step = step.replace('{{height}}', properties[5].replace(');', ''));
                            step = step.replace('{{imgid}}', properties[0]);
                            step = step.replace('{{indicatorid}}', properties[1]);
                            step = $(step);

                            if (1 < mouseovers.length) {
                                step.add(step.find(s.infodition)).find(s.mouseover + ':eq(' + i + ')').addClass(s.active);
                            }

                            step.find(s.mouseover).removeAttr('onmouseover');

                            step = step[0].outerHTML;

                        } else {
                            step = $(step).find(s.img).removeAttr('style').html(templates.alt).end()[0].outerHTML;
                        }

                        substeps.push(step);
                    }
                });

                stepcount += substeps.length;
                ol = ol.replace('{{steps}}', substeps.join(''));

                ols.push(ol);
            });

            manual = manual.replace('{{stepcount}}', stepcount);
            manual = manual.replace('{{steps}}', ols.join(''));

            target.html(manual);

            if (carousel) {
                vf['world-manual-article'].carousel(target);
            }
        },

        carousel: function(target) {
            var s = this.settings,
                carousel = target.find(s.carousel),
                steps = target.find(s.step),
                height = 0;

            steps.eq(0).addClass(s.display.substr(1));

            carousel.find(s.nav).on('click', $.proxy(this.navigate, carousel));
            carousel.find(s.refresh).on('click', $.proxy(this.refresh, target));
        },

        navigate: function(e) {
            e.preventDefault();

            var s = vf['world-manual-article'].settings,
                data = s.data,
                target = $(this),
                steps = target.find(s.step),
                source = $(e.currentTarget),
                display = s.display.substr(1),
                disabled = s.disabled.substr(1),
                curpage = target.data(data.page),
                count = steps.length - 1,
                page = Math.min(Math.max(curpage + source.data(data.direction), 0), count);

            target.data(data.page, page);

            steps.removeClass(display).eq(page).addClass(display);

            target.find(s.page).find('span').html(page + 1);

            target.find(s.disabled).removeClass(disabled);

            if (!(page % count)) {
                source.addClass(disabled);
            }
        },

        refresh: function(e) {
            e.preventDefault();

            $('a[data-direction="-1"]').addClass('wm-disabled');

            vf['world-manual-article'].init($(this));
        },

        indicatorPosition: function(left, top, width, height) {

            var maxWidth = 320;
            var maxHeight = 385;
            var ratio = 1,
                heightRatio, widthRatio;

            if (width > maxWidth || height > maxHeight) {

                heightRatio = height / maxHeight;
                widthRatio = width / maxWidth;

                if (widthRatio > heightRatio) {

                    ratio = widthRatio;

                } else {

                    ratio = heightRatio;

                }

                top = top / ratio;
                left = left / ratio;
            }

            // Return an object that we can use
            return {

                'top': top - 15,
                'left': left - 20
            };
        }
    };
}(vf));
