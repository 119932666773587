(function() {
    vf['secondary-search'] = {
        settings: {
            target: '.mod-secondary-search'
        },
        init: function(context) {
            $(context).on('focus', '.mod-secondary-search input', vf['secondary-search'].moduleFocus);
            $(context).on('click', '.mod-secondary-search input', vf['secondary-search'].moduleFocus);
            $(context).on('click', '.mod-secondary-search button', vf['secondary-search'].formSubmit);
            $(context).on('click', '.mod-secondary-search li a', vf['secondary-search'].selectContext);
            $(document).on('click', vf['secondary-search'].clickedOutside);
            $(document).on('keyup', vf['secondary-search'].escapePressed);
        },
        moduleFocus: function(e) {
            var s = vf['secondary-search'].settings;
            var searchContext = ['private', 'domain.support' ];
            e.preventDefault();
            $(this).parent().find('input[name="searchContext"]').remove();
            if ( 'undefined' !== typeof $('.secondary-search-menu li.active a').data('context') ){
                searchContext[1] = 'domain.allg-info';
            }
            searchContext[0] = ( !VF.getSessionStorage('selectedCustomerContextFacetValue') ? ( 0 === $('.nav-search ').find('input[name="searchCustomerContext"]').length ) ? 'private' : $('.nav-search').find('input[name="searchCustomerContext"]').val().split('|')[0] : 'private');
            $(this).parent().find('input[name="searchCustomerContext"]').val(searchContext.join('|'));
            $(this).closest(s.target).addClass('search-focused');
        },
        clickedOutside: function(e) {
           // var s = vf['secondary-search'].settings;
            if (0 === $(e.target).closest('.mod-secondary-search').length) {
                $('.mod-secondary-search').removeClass('search-focused');
            }
        },
        escapePressed: function(e) {
           // var s = vf['secondary-search'].settings;
            if (27 === e.keyCode) {
                $('.mod-secondary-search').removeClass('search-focused');
            }
        },
        formSubmit: function(e) {
            e.preventDefault();
            var searchString = $(this).siblings('input[type=text]').val();
            var searchTopic = $(this).siblings('input[type=hidden][name="searchTopic"]');
            if (0 < searchTopic.length) {
                searchString = searchString + ' ' + searchTopic.val();
                $(this).siblings('input[type=text]').val(searchString);
            }
            $(this).closest('form').submit();
        },
        selectContext: function(e) {
            var s = vf['secondary-search'].settings;
            e.preventDefault();
            var el = $(this), //should return the anchor
                targetEl = $(this).closest(s.target), //should return the main module div
                searchContext = el.data('context').toLowerCase() || '', //on anchor there's an attribute named data-context and that's there if you want to have a different display name than the submitted form value
                searchContextText = el.text() || '',
                globalSearchParameters = targetEl.find('.globalSearchParams').html(), //List of hidden inputs if the selected category was "Search all of the website"
                searchThisTopicParameters = targetEl.find('.otherSearchParams').html();

            el.closest('ul').find('li').removeClass('active');
            el.closest('li').addClass('active');
            el.closest('.secondary-search-menu').fadeOut(function() {
                $(this).attr('style', ''); //Cleares any remaining values from the fadeout animation
                targetEl.removeClass('search-focused'); //This hides the drop down
                targetEl.find('.secondary-search-content input[type=text]').attr('placeholder', window.globalconfig.searchBoxPrefix + ' ' + searchContextText); //Setting the placeholder value
            });
            //Code for handling different search categories
            switch (searchContext) {
            case 'inthistopic':
                //targetEl.find('form input[type=hidden]').remove();
                targetEl.find('form').append(searchThisTopicParameters);
                break;
            case 'globalsearch':
               // targetEl.find('form input[type=hidden]').remove();
                targetEl.find('form').append(globalSearchParameters);
                break;
            case 'inalltopics':
               // targetEl.find('form input[type=hidden]').remove();
               // targetEl.find('form').append(searchThisTopicParameters);
                targetEl.find('form input[type=hidden][name="searchTopic"]').remove();
                break;
            }
           /* if ('inthistopic' === searchContext) {
                targetEl.find('form input[type=hidden]').remove();
                targetEl.find('form').append(searchThisTopicParameters);
            } else if ('globalsearch' === searchContext) {
                targetEl.find('form input[type=hidden]').remove();
                targetEl.find('form').append(globalSearchParameters);
            } else if ('inalltopics' === searchContext) {
                targetEl.find('form input[type=hidden]').remove();
                targetEl.find('form').append(searchThisTopicParameters);
                targetEl.find('form input[type=hidden][name="searchTopic"]').remove();
            }*/
            targetEl.find('.secondary-search-content input[type=text]').focus();

        }

    };
}(vf));
