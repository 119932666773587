(function () {

    vf['file-upload'] = {
        settings: {
            target: '.mod-file-upload'
        },

        init: function (context) {
            var s = vf['file-upload'].settings;

            $(s.target, context).addBack(s.target).each(function () {

                var module = $(this),
                    text = module.find('input[type=text]'),
                    submit = module.find('input[type=file]'),
                    submitProx = module.find('.submit-proxy');

                // Check for the various File API support.
                if (window.File && window.FileReader && window.FileList && window.Blob) {
                    // File APIs are supported.

                    var links = text.add(submitProx);

                    links.click(function (e) { e.preventDefault(); submit.click(); });

                    submit.on('change', function (e) {
                        var files = e.target.files;

                        //Check if a file has  been selected and apply name to textfield
                        if (files[0]) { text.val(files[0].name); }
                    });
                } else {
                    // If javascript is available but the Api's are not them we need
                    // to hide the textfiled as we cannot populate it
                    // Hiding the proxy submit looks nicer forcontinuity
                    text.hide();
                    submitProx.hide();
                    $('.browse').show();
                }
            });

        }

    };

}(vf));
