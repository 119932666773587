(function () {
    vf.wm.slider = {
        init: function (context) {
            var selector = '.wm-slider',
                instances = [];

            $(selector, context).addBack(selector).each(function () {
                var elem = $(this),
                    module = new wmslider(elem);

                // Store the instance on the module for later changes
                elem.data('wm-slider', module);

                // Reset the slider on resize and orientation changes
                $(window).on('vf::resize', function () {

                    // TO DO Optimise the vf::resize function with a setTimeout
                    module.resize();

                });

                module.init();

                // Create an array of instances to reference if required later (e.g. wm.js)
                instances.push(module);

            });

            return instances;

        }

    };

    var wmslider = function (elem) {
        this.elem = elem;
        this.stage = {};
        this.rail = {};
        this.props = [];
        this.propWidth = 0;
        this.activeProp = 0;

        $.extend(this, {
            init: function () {
                this.getelements();
                this.resize();

                // More manual touch threshold and control
                var mod = this;
                this.stage.on('movestart', function (e) {
                    var ishorizontal = (
                        (e.distY > e.distX && e.distY < -e.distX) || (e.distY < e.distX && e.distY > -e.distX)
                    ),
                        offset = 0;

                    // Left or right touch gesture - return an offset of 1 or -1
                    if (ishorizontal) {
                        offset = 1;
                        var threshold = Math.abs(e.distX);
                        if (0 < e.distX) { offset = -1; }
                        if (10 < threshold) { mod.goToOffset(offset); }
                    }
                    else {
                        e.preventDefault();
                    }

                });

                return this;

            },
            getelements: function () {
                this.stage = this.elem;
                this.rail = this.stage.children('ul');
                this.props = this.rail.children('li');
                return this;
            },
            resize: function () {
                var propWidth = this.stage.outerWidth(),
                    propHeight = this.getTallestPropHeight();

                // Set the individual slide heights and widths
                this.props.outerWidth(propWidth).outerHeight(propHeight);

                // Set the rail height and width to host all the slides
                this.rail.outerWidth(this.props.length * propWidth).outerHeight(propHeight);

                // Set the stage height - the stage holds the rail and slides/props
                this.stage.outerHeight(propHeight);

                // Set the instance propWidth var for goToProp
                this.propWidth = propWidth;

                // Toggle the correct slide
                this.goToProp(this.activeProp, false);

                return this;

            },
            getTallestPropHeight: function () {
                var height = 0;
                this.props.each(function () {
                    var propHeight = $(this).css('height', '').outerHeight();
                    if (propHeight > height) { height = propHeight; }
                });
                return height;
            },
            goToOffset: function (offset) {

                // Take the offset (e.g. 1 or -1) and calculate the next slide to move too
                var targetProp = this.activeProp + offset;

                // If smaller than 0 - do nothing
                if (0 > targetProp) { targetProp = 0; }

                // If there is no slide in the props index - set back to the original
                if (targetProp > (this.props.length - 1)) {
                    targetProp = this.props.length - 1;
                }

                // Animate to the correct slide
                this.goToProp(targetProp, true);

                return this;

            },
            goToProp: function (i, animate) {

                // Set the required left position for the rail - always bigger than 0
                var targetLeft = '-' + (i * this.propWidth) + 'px';

                if (animate) {
                    this.rail.animate({
                        'left': targetLeft
                    }, 200);
                }
                else {
                    this.rail.css('left', targetLeft);
                }

                // Set the slider instance var
                this.activeProp = i;

                return this;

            }
        });
    };
}(vf));
