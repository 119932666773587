(function () {
    vf['world-manual-troubleshoot'] = {
        settings: {
            target: '.mod-world-manual-troubleshoot',
            context: {},
            xmlPattern: ''
        },

        init: function (context) {
            var s = this.settings;
            var data, html;

            $(s.target, context).addBack(s.target).each(function () {

                var target = $(this);													// store module as $ object

                s.context = target;

                var xmlPatteren = target.attr('data-xml-pattern'),								// strore the parts to create url
                    stepId = target.attr('data-start-id'),									// grab first id
                    xmlSource = xmlPatteren.replace('{{id}}', stepId);							// create url for first step

                s.xmlPattern = xmlPatteren;


                $.get(xmlSource).done(function (data) {											// ajax request local XML

                    if ($(data).find('TroubleshootingStart').text()) {							// check to ensure we have start xml file

                        vf['world-manual-troubleshoot'].start(data);		// fire start function passing $moduele, XML data, and URL patteren

                    }

                });

            });
        },

        start: function (data) {

            // there are two types of start:
            // 1 // where client is asked yes or no question first
            // 2 // where there is only a start button to begin

            var s = this.settings;

            data = $(data);
            var info = data.find('Info').text(),										// grab info text from xml
                decisionTitle = data.find('DecisionTitle').text(),							// grab title text from xml
                body = data.find('Body').text().split('?'),
                stepId = data.find('StartCauseId').text(),
                xmlSource = s.xmlPattern.replace('{{id}}', stepId),						// store next xml file iD
                YesCauseId = data.find('YesCauseId').text() || '0',
                NoCauseId = data.find('NoCauseId').text() || '0';

            // yes/no
            if ('0' !== NoCauseId || '0' !== YesCauseId) {

                html = '<h3>' + decisionTitle + '</h3>' +										// create html for title and text
                    '<p>' + info + '</p>' +
                    vf.config.worldmanual.troubleshooting.startStrg +
                    '<a id="yes-cause-id" class="btn btn-alt btn-sml" href="#troubleshoot-content">' +
                    vf.config.worldmanual.troubleshooting.yes +
                    '</a>' +
                    '<a id="no-cause-id" class="btn btn-alt btn-sml" href="#troubleshoot-content">' +
                    vf.config.worldmanual.troubleshooting.no +
                    '</a>';

                // start
            } else {

                html = '<h3>' + decisionTitle + '</h3>' +										// create html for title and text
                    '<p>' + info + '</p>';

                if ('' != body) { html += '<div class="alert warning"><i class="i-warning"></i><div><h3>' + body[0] + '?</h3><p>' + body[1].replace('</br>', '') + '</p></div></div>'; }

                html += vf.config.worldmanual.troubleshooting.startStrg +
                    '<a id="troubleshoot-start" class="btn btn-alt btn-sml" href="#troubleshoot-content">' +
                    vf.config.worldmanual.troubleshooting.Start +
                    '</a>';
            }

            s.context.find('#troubleshoot-content').prepend(html);

            // add title and text to page


            // yes/no cause buttons
            $('#yes-cause-id').on('click', function (e) {

                e.preventDefault();
                stepId = YesCauseId;
                vf['world-manual-troubleshoot'].cause(stepId);
            });
            $('#no-cause-id').on('click', function (e) {

                e.preventDefault();
                stepId = NoCauseId;
                vf['world-manual-troubleshoot'].cause(stepId);
            });


            // start button
            $('#troubleshoot-start').on('click', function (e) {									// click start button

                e.preventDefault();
                vf['world-manual-troubleshoot'].cause(stepId);				// fire cause function and pass in $module and url patteren
            });

        },

        cause: function (stepId) {

            var s = this.settings;

            var xmlSource = s.xmlPattern.replace('{{id}}', stepId);

            $.get(xmlSource).done(function (data) {

                if ($(data).find('TroubleshootingCause').text()) {

                    data = $(data),
                        article = '',
                        Body = data.find('Body').text() || '',
                        link = $(Body).attr('href').match('http://|https://') ? true : false;


                    if (0 < Body.indexOf('href') && false === link) {

                        var articleUrl = $(Body).attr('href');

                        $.get(articleUrl).done(function (articleData) {

                            article = '<div class="section">' + articleData + '</div>';

                            vf['world-manual-troubleshoot'].build(data, link, Body, article);

                        }).fail(function () {

                            // console.log('error');

                        });

                    } else {

                        vf['world-manual-troubleshoot'].build(data, link, Body, article);

                    }

                }

            });

        },

        build: function (data, link, Body, article) {

            var s = vf['world-manual-troubleshoot'].settings;

            var CauseTitle = data.find('CauseTitle').text() || '',
                Info = data.find('Info').text() || '',
                SequenceText = data.find('SequenceText').text() || '',
                SolutionTitle = data.find('SolutionTitle').text() || '',
                DecisionTitle = data.find('DecisionTitle').text() || '',
                stepId = data.find('NextCauseId').text() || '',
                NextCauseId = data.find('NextCauseId').text() || '0',
                bodyText = Body.match(/<a[^>]*>(.*?)<\/a>/);

            if (bodyText && false === link) {
                Body = Body.replace(bodyText[0], bodyText[1]);
            }

            var html = '<h3>' + CauseTitle + '</h3>' +
                '<div class="section">' +
                '<h4>' + SequenceText + '</h4>' +
                '<p>' + Info + '</p>' +
                '</div>' +
                '<div class="section">' +
                '<h4>' + SolutionTitle + '</h4>' +
                '<p>' + Body + '</p>' +
                '</div>' +
                '<hr>' +
                article +
                '<div class="end-section">' +
                '<span>' + DecisionTitle + '</span>' +
                '<a id="resolved" class="btn btn-alt btn-sml" href="#troubleshoot-content">' +
                vf.config.worldmanual.troubleshooting.yes +
                '</a>' +
                '<a id="unresolved" class="btn btn-alt btn-sml" href="#troubleshoot-content">' +
                vf.config.worldmanual.troubleshooting.no +
                '</a>' +
                '</div>';

            s.context.find('#troubleshoot-content').html(html);
            vf.util.initModules(s.context.find('#troubleshoot-content'));

            // resolved button
            $('#resolved').on('click', function (e) {

                e.preventDefault();
                vf['world-manual-troubleshoot'].resolved();

            });


            // unresolved button
            $('#unresolved').on('click', function (e) {

                e.preventDefault();

                // if there are no more possible causes
                if ('0' === NextCauseId) {

                    vf['world-manual-troubleshoot'].unresolved(stepId);

                    // load another cause article (refire function passing in new stepId)
                } else {

                    vf['world-manual-troubleshoot'].cause(stepId);
                }

            });

        },

        resolved: function () {

            var s = this.settings,
                xmlSource = vf.config.worldmanual.troubleshooting.resolvedUrl;

            $.get(xmlSource).done(function (data) {

                var Body = $(data).find('Body').text(),
                    html = '<p>' + Body + '</p>';

                s.context.find('#troubleshoot-content').html(html);

            });

        },

        unresolved: function (stepId) {



            var s = this.settings,
                xmlSource = vf.config.worldmanual.troubleshooting.unresolvedUrl;

            $.get(xmlSource).done(function (data) {

                var Body = $(data).find('Body').text(),
                    html = '<p>' + Body + '</p>';

                s.context.find('#troubleshoot-content').html(html);

            });

        }

    };

}(vf));
