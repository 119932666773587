(function() {
    vf['world-manual-spec'] = {
        settings: {
            target: '.mod-world-manual-spec'
        },

        init: function(context) {
            var s = this.settings;

            $(s.target, context).addBack(s.target).each(function() {

                var target = $(this),
                    xmlSource = target.attr('data-xml-src');


                $.get(xmlSource).done(function(data) {

                    vf['world-manual-spec'].table(data, target);

                });


            });
        },

        table: function(data, target) {

            var body = $(data).find('Body').text(),
                table = body.split('</table>'),
                tables = '';


            for (var i = 0, _i = table.length - 1; i < _i; i++) {

                tables += table[i] + '</table>';

            }

            target.find('#spec-table').html(tables);

        }

    };

}(vf));
