(function() {

    vf['prevent-close'] = {
        settings: {
            target: '.mod-prevent-close',
            triggers: '.prevent-close-triggers'
        },

        init: function(context) {
            var s = vf['prevent-close'].settings;

            $(s.target, context).addBack(s.target).each(function() {

                var module = $(this),
                    links = module.find(s.triggers + ' a');

                links.on('click', function(e) {
                    var t = setTimeout(function() {
                        links.removeAttr('href').removeClass('tg-head');
                    }, 100);
                });
            });
        }
    };
}(vf));
