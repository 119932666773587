(function () {
    vf.feedback = {
        settings: {
            target: '.mod-feedback'
        },

        init: function (context) {
            var s = this.settings;

            $(s.target, context).addBack(s.target).each(function () {
                var feedback = $(this),
                    solved = feedback.find('.solved');

                solved.on('click', vf.feedback.updateBtn);
            });
        },

        updateBtn: function (e) {

            var str = 'We are glad your problem is solved';

            $(this).prev().html(str);
        }
    };
}(vf));
