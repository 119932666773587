(function() {
    vf['profile-chooser'] = {
        settings: {
            target: '.wm-profile-chooser'
        },
        init: function(context) {
            $(context).on('click', '.profile-chooser-list li a', vf['profile-chooser'].displayProfile);
        },
        displayProfile: function(e) {
            e.preventDefault();
            var itemIndex = $(this).closest('li').index();
            var profileList = $(this).closest('.profile-chooser-list');
            var profilesContent = profileList.next('.profile-chooser-content');
            profileList.find('li').removeClass('active');
            profilesContent.find('.profile-item-content').hide();
            $(this).closest('.wm-profile-chooser').addClass('wm-profile-chooser-active');
            $(this).closest('li').addClass('active');
            profilesContent.find('.profile-item-content:eq(' + itemIndex + ')').show();
            if (620 > $(window).width()) {
                var profileChooserHeight = $('.profile-chooser-list').height();
                console.log(profileChooserHeight);
                $('html, body').animate({
                    scrollTop: $('.profile-item-content:eq(' + itemIndex + ')').offset().top - 80
                }, 500);
            }
        }

    };
}(vf));
