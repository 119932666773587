(function() {
    vf['world-manual-video'] = {
        settings: {
            target: '.mod-world-manual-video'
        },

        init: function(context) {
            var s = this.settings;

            $(s.target, context).addBack(s.target).each(function() {


                var target = $(this),
                    xmlSource = target.attr('data-xml-source');


                $.get(xmlSource).done(function(data) {

                    vf['world-manual-video'].video(target, data);

                });

            });
        },

        video: function(target, data) {

            var title = $(data).find('VideoURL').text(),
                id = title.split('https://www.youtube.com/v/')[1].replace('?', '');

            target.find('.youtube').attr('id', id);

            vf.youtube.init();
        }

    };

}(vf));
