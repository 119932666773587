/**
 * JS Module for Device Chooser.
 * @returns {undefined}
 */
(function () {
    vf['device-chooser'] = {
        settings: {
            target: '.mod-device-chooser',
            backlinks: 'a.wm-backlink',
            nextlinks: 'li.active .cta-topic a'
        },
        init: function (context) {
            var s = this.settings;
            var self = this;
            /*
             * add class border-top to the first element for rounded
             * corners at the beginning of the list. Only mobile
             */
            var item = $('li.list-sml')[0];
            $(item).addClass('border-top');

            /* Not needed in OpenText. Even all the filter Functions
             * and link functions
             */
            self.filterType();
            self.filterDevice();
            this.initBacklinks();
            this.initNextlinks();
        },
        /**
         * Filters the manufacturers by type. Not needed at cms
         *
         * @returns {undefined}
         */
        filterType: function () {
            var type = window.location.href.match(/type=([a-z]+)\&?/);
            if (type) {
                $('.type .preselect').html(type[1]);
                //First hide all
                $('.manufacturer .cta-topic li.item').addClass('access');
                //Display "type"
                $('.data-' + type[1]).removeClass('access');
                //Hide all over 7?
//                                $('.manufacturer .cta-topic li.item:gt(7)').not('.access').addClass('access');
            }
        },
        /**
         * Filters the devices by manufacturer and type. Not needed at cms
         *
         * @returns {undefined}
         */
        filterDevice: function () {
            var type = window.location.href.match(/type=([a-z]+)\&?/);
            var manufacturer = window.location.href.match(/manufacturer=([a-z]+)\&?/);
            if (manufacturer && type) {
                $('.manufacturer .preselect').html(manufacturer[1]);
                $('.model .cta-topic li').addClass('access');
                $('.model .cta-topic li.data-' + manufacturer[1] + '.data-' + type[1]).removeClass('access');
            }
        },
        /**
         * Creates backlinks with type and manufacturer in url. Not needed in cms
         *
         * @returns {undefined}
         */
        initBacklinks: function () {
            var type = window.location.href.match(/type=([a-z]+)\&?/);
            if (type) {
                $.each($(this.settings.backlinks), function (index, value) {
                    var href = $(value).attr('href');
                    href += '?type=' + type[1];
                    $(value).attr('href', href);
                });
            }
            var manufacturer = window.location.href.match(/manufacturer=([a-z]+)\&?/);
            if (manufacturer) {
                $.each($(this.settings.backlinks), function (index, value) {
                    var href = $(value).attr('href');
                    href += '&manufacturer=' + manufacturer[1];
                    $(value).attr('href', href);
                });
            }
        },
        /**
         * Init the next link adding the type to url. Not needed in cms
         *
         * @returns {undefined}
         */
        initNextlinks: function () {
            var type = window.location.href.match(/type=([a-z]+)\&?/);
            if (type) {
                $.each($(this.settings.nextlinks), function (index, value) {
                    var href = $(value).attr('href');
                    if (!href.match(/type=([a-z]+)\&?/)) {
                        href += '&type=' + type[1];
                        $(value).attr('href', href);
                    }
                });
            }
        }
    };
}(vf));
