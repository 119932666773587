$(document).ready(function(){
    //Set an digitags Cookie for Digitags
    var forDigitags = window.location.href.match(/forDigitags=true\&?/);
    if (forDigitags && 1 === forDigitags.length){
        $.cookie('forDigitags', true);
    }
    //Adding class for Digitags
    if ($.cookie('forDigitags')){
        $('body').addClass('digitags-mode app-mode');
    }
});
